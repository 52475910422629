//---------------------------订单管理
// 门店管理
const storeSaleOrders = () => import('./view/storeManage/orderList.vue')
const storeReturnOrders = () => import('./view/storeManage/returnList.vue')
//---------------------------系统管理
// 地址库管理
const addressBankManagement = () => import('./view/saleStrategyManage/addressBankManagement.vue')
// 快递规则（临时）
const expressInterimRule = () => import('./view/saleStrategyManage/expressInterimRule.vue')
//系统
const MicroWeb = () => import('./view/micro/index.vue')

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/login',
    component: () => import('./view/index'),
    children: [
      //门店管理
      {
        path: 'storeManage/orderList',
        name: 'storeSaleOrders',
        component: storeSaleOrders, // 销售订单
      },
      {
        path: 'storeManage/returnList',
        name: 'storeReturnOrders',
        component: storeReturnOrders, // 销售订单
      },
      {
        path: 'systemSettings/addressBankManagement',
        name: 'addressBankManagement',
        component: addressBankManagement, // 地址库管理
      },
      {
        path: 'systemSettings/expressInterimRule',
        name: 'expressInterimRule',
        component: expressInterimRule, // 快递规则（临时）
      },
      //微应用
      {
        path: 'orderManage',
        name: 'orderManage',
        component: MicroWeb,
      },
      {
        path: 'orderManage/*',
        name: 'orderManage/',
        component: MicroWeb,
      },
      {
        path: 'aftersale_web',
        name: 'aftersale_web',
        component: MicroWeb,
      },
      {
        path: 'aftersale_web/*',
        name: 'aftersale_web/',
        component: MicroWeb,
      },
      {
        path: 'purchase',
        name: 'purchase',
        component: MicroWeb,
      },
      {
        path: 'purchase/*',
        name: 'purchase/',
        component: MicroWeb,
      },
      {
        path: 'distributionManagement',
        name: 'distributionManagement',
        component: MicroWeb,
      },
      {
        path: 'distributionManagement/*',
        name: 'distributionManagement/',
        component: MicroWeb,
      },
      {
        path: 'distributionPlatform',
        name: 'distributionPlatform',
        component: MicroWeb,
      },
      {
        path: 'distributionPlatform/*',
        name: 'distributionPlatform/',
        component: MicroWeb,
      },
      {
        path: 'workorder_web',
        name: 'workorder_web',
        component: MicroWeb,
      },
      {
        path: 'workorder_web/*',
        name: 'workorder_web/',
        component: MicroWeb,
      },
      {
        path: 'inventory_web',
        name: 'inventory_web',
        component: MicroWeb,
      },
      {
        path: 'inventory_web/*',
        name: 'inventory_web/',
        component: MicroWeb,
      },
      {
        path: 'report_web',
        name: 'report_web',
        component: MicroWeb,
      },
      {
        path: 'report_web/*',
        name: 'report_web/',
        component: MicroWeb,
      },
      {
        path: 'dashboard_web',
        name: 'dashboard_web',
        component: MicroWeb,
      },
      {
        path: 'dashboard_web/*',
        name: 'dashboard_web/',
        component: MicroWeb,
      },
      {
        path: 'campaign_web',
        name: 'campaign_web',
        component: MicroWeb,
      },
      {
        path: 'campaign_web/*',
        name: 'campaign_web/',
        component: MicroWeb,
      },
      {
        path: 'order',
        name: 'wmshub_web',
        component: MicroWeb,
      },
      {
        path: 'order/*',
        name: 'wmshub_web/',
        component: MicroWeb,
      },
      {
        path: 'shop_web',
        name: 'shop_web',
        component: MicroWeb,
      },
      {
        path: 'shop_web/*',
        name: 'shop_web/',
        component: MicroWeb,
      },
      {
        path: 'prepayment_web',
        name: 'prepayment_web',
        component: MicroWeb,
      },
      {
        path: 'prepayment_web/*',
        name: 'prepayment_web/',
        component: MicroWeb,
      },
      {
        path: 'strategy_web',
        name: 'strategy_web',
        component: MicroWeb,
      },
      {
        path: 'strategy_web/*',
        name: 'strategy_web/',
        component: MicroWeb,
      },
      {
        path: 'goodsManage',
        name: 'goodsManage',
        component: MicroWeb,
      },
      {
        path: 'goodsManage/*',
        name: 'goodsManage/',
        component: MicroWeb,
      },
      {
        path: 'region_web',
        name: 'region_web',
        component: MicroWeb,
      },
      {
        path: 'region_web/*',
        name: 'region_web/',
        component: MicroWeb,
      },
      {
        path: 'fms_web',
        name: 'fms_web',
        component: MicroWeb,
      },
      {
        path: 'fms_web/*',
        name: 'fms_web/',
        component: MicroWeb,
      },
      {
        path: '/404',
        name: '404',
        component: () => import('./view/common/404'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./view/login'),
  },
  {
    path: '/csvPreview',
    name: 'csvPreview',
    component: () => import('./view/csvPreview'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./view/common/403'),
  },
  // 此处需特别注意至于最底部
  {
    path: '*',
    redirect: '/404',
  },
]

export default routes
