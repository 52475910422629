/**
 * [设置cookie]
 * @param  {Object}   cookies  [要写入document.cookie中的对象]
 * @param  {Function} callback [cookie设置成功的回调函数]
 */
function setCookie(cookies, callback) {
  var now = new Date()
  var time = now.getTime()
  var expireTime = time + 1000 * 3600 * 24 * 7 // 单位为毫秒 cookie生命周期设置为7天
  now.setTime(expireTime)

  for (let key in cookies) {
    document.cookie = key + '=' + cookies[key] + ';expires=' + now.toGMTString() + ';path=/'
  }

  callback && callback()
}

/**
 * [按名称获取cookie的值]
 * @param  {String} cookieName [cookie名称]
 * @return {String}            [cookie的值]
 */
function getCookie(cookieName) {
  var cookies = {},
    cookieArr = document.cookie.split(';'),
    currentCookie = ''

  for (let i = 0; i < cookieArr.length; i++) {
    currentCookie = cookieArr[i]

    if (currentCookie.indexOf('=') > -1) {
      cookies[currentCookie.split('=')[0].trim()] = currentCookie.split('=')[1].trim()
    }
  }

  if (typeof cookies[cookieName.trim()] != 'undefined') {
    return cookies[cookieName.trim()]
  } else {
    return false
  }
}

/**
 * [删除cookie]
 * @param  {Function} callback [删除成功的回调函数]
 */
function deleteCookie(callback) {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  console.log(keys)
  if (keys) {
    for (var i = keys.length; i--; ) {
      document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() //清除当前域名下的,例如：m.kevis.com
      document.cookie =
        keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() //清除当前域名下的，例如 .m.kevis.com
      document.cookie =
        keys[i] + '=0;path=/;domain=geezdata.com;expires=' + new Date(0).toUTCString() //清除一级域名下的或指定的，例如 .kevis.com
    }
  }
  callback && callback()
}

export { setCookie, getCookie, deleteCookie }
