import constants from './common/constants'
import './common/common'
import './public-path'
import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入bootstrap
import App from './App.vue' // 根组件
import routes from './router.js'
import store from './store'
import { priv } from './common/privilege.js'
import { isEmptyObject, toThousandAmount } from './common'
import jsSHA from 'jssha'
import './css/index.scss'
import './utils/antd'
import './lib/layer/layer'
import './lib/layer/skin/layer.css'
import breadcrumb from '@component/breadcrumb/index'
import VuePapaParse from 'vue-papa-parse'
import { registerMicroApps, addErrorHandler, prefetchApps } from 'qiankun'
import apps from './qiankun/apps.js'
import { message, Modal } from 'ant-design-vue'
import i18n from './utils/lang'
import actions from './qiankun/actions2'
import { getCookie } from '@/common/cookie'
import { eventBus } from './utils/eventBus'

// 设置一个全局参数，让孙应用知道在哪个环境
window.__QIANKUN_PARENTNAME__ = 'OMS'

registerMicroApps(apps)

// 预加载子应用
prefetchApps([apps[0]])

Vue.prototype.$jsSHA = jsSHA
Vue.component('breadcrumb', breadcrumb)

const env = 'dev'

Vue.config.devtools = env === 'dev' ? true : false
Vue.config.silent = env === 'dev' ? false : true

Vue.prototype.priv = function () {
  return priv.apply(this, arguments)
}

Vue.prototype.global = {}
Vue.prototype.constants = constants

Vue.prototype.global.currentSession = null
Vue.prototype.global.currentModule = null

Vue.prototype.showShop = true

Vue.prototype.$isEmptyObject = isEmptyObject
Vue.prototype.toThousandAmount = toThousandAmount

Vue.use(VuePapaParse)
Vue.prototype.layer = {
  empty: () => {
    //搜索为空
    // layer.msg(`<span style='color:red'>${i18n.t('暂无数据')}~</span>`, {
    //   icon: 5,
    // })
    // message.success(i18n.t('操作成功'))
  },
  success: () => {
    //操作成功
    message.success(i18n.t('操作成功'))
  },
  selectAnOrder: () => {
    //选择一个订单
    message.warn(i18n.t('请选择订单'))
  },
}

// 添加错误处理函数
addErrorHandler((error) => {
  // 在这里对子应用加载错误进行处理
  console.error('子应用加载错误:', error)
  if (process.env.NODE_ENV === 'production') {
    Modal.error({
      title: i18n.t('页面出错了'),
      content: i18n.t('请尝试浏览器无痕浏览访问，仍不行请联系技术人员'),
    })
  }
})

let router = null
let instance = null
function render(props = {}) {
  const { container, erpLogout, erpJump, showAppDrawer } = props
  if (props) {
    // 注入 actions 实例
    actions.setActions(props)
    Vue.prototype.$erpLogout = erpLogout
    Vue.prototype.$erpJump = erpJump
    Vue.prototype.$showAppDrawer = showAppDrawer
  }
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? `OMS` : '/',
    mode: 'history',
    routes,
  })

  router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
      if (!getCookie('d_session_id')) {
        next()
      } else {
        next({ name: 'orderManage' })
      }
    } else if (!getCookie('d_session_id')) {
      localStorage.setItem('pageCacheFullPath', to.fullPath)
      next({ name: 'login' })
      return
    }
    next()
  })

  Vue.use(VueRouter)
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export function getRouter() {
  return router
}

export async function bootstrap() {
  console.log('[vue2] OMS app bootstraped')
}

export async function mount(props) {
  render(props)

  props.onGlobalStateChange((state) => {
    eventBus.$emit('menuChange', state.menuList)
    store.commit('setState', { currentApp: state.currentApp })
  })
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}
