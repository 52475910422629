import i18n from '@/utils/lang'
export default {
  loading: false, //加载全局spin Loading
  lang: localStorage.getItem('lang') || 'zh',
  locale: getData('locale', {}),
  shopList: [], //店铺列表
  userInfo: getData('userInfo', {}),
  userSession: getData('userSession', {}),
  currentShop: getData('currentShop', {}),
  currentApp: getData('currentApp', {}), //当前产品
  shopCode: '',
  nowPlatformList: [], //当前的平台列表，通过店铺来获取的。
  countryMap: {
    th: 1, //泰国
    TH: 1, //泰国
    Thailand: 1, //泰国
    VN: 2, //越南
    PH: 3, //菲律宾
    Philippines: 3, //菲律宾
    MY: 4, //马来西亚
    Malaysia: 4, //马来西亚
    CN: 6, //中国
    China: 6, //中国
  },
  exclude: null,
  panes: getData('panes', []),
  shopPlatformList: getData('shopPlatformList', []),
  pagination_setting: {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
  dashboard_loading: false,
  menuList: getData('menuList', []) || getData('menus', []),
  orderDetailStateInfo: {},
  orderSubTypeMapping: {
    RAW_MATERIAL_REORDER: i18n.t('原料补单'),
    FINISHED_PRODUCT_REORDER: i18n.t('成品补单'),
    DESSERT_REORDER: i18n.t('甜品补单'),
    SEMI_FINISHED_PRODUCT_REORDER: i18n.t('半成品补单'),
    RAW_MATERIAL_ORDER: i18n.t('原物料订货'),
    FINISHED_PRODUCT_ORDER: i18n.t('成品订货'),
    DESSERT_ORDER: i18n.t('甜品订单'),
    SEMI_FINISHED_PRODUCT_ORDER: i18n.t('半成品订单'),
    TEA_BREAK_ORDER: i18n.t('茶歇订单'),
    BIG_CAKE_ORDER: i18n.t('大蛋糕订单'),
    KITCHEN_GIFT_PRODUCT: i18n.t('厨房赠送产品'),
    PRODUCTION_ORDER: i18n.t('生产订货'),
    //运营领用
    OPERATION_USE: i18n.t('运营领用'),
    //报废出库
    SCRAP_OUTBOUND: i18n.t('报废出库'),
    //办公领用
    OFFICE_USE: i18n.t('办公领用'),
    //研发领用
    RESEARCH_USE: i18n.t('研发领用'),
    //留样出库/检查抽样
    SAMPLE_OUTBOUND: i18n.t('留样出库/检查抽样'),
    //员工福利
    EMPLOYEE_WELFARE: i18n.t('员工福利'),
    //低值易耗品出库
    LOW_VALUE_CONSUMABLE_OUTBOUND: i18n.t('低值易耗品出库'),
    //设备出库
    EQUIPMENT_OUTBOUND: i18n.t('设备出库'),
    //办公家具出库
    OFFICE_FURNITURE_OUTBOUND: i18n.t('办公家具出库'),
    TRANSFER_ORDER: i18n.t('调拨订货'),
  },
  microApplicationList: new Map([]), // 已经注册的微应用列表
  show_micro: false, //展示子应用

  goodsTypeMapping: {
    GENERAL: i18n.t('成品'),
    SEMI_FINISHED_PRODUCT: i18n.t('半成品'),
    RAW_MATERIAL: i18n.t('原料'),
    VIRTUAL: i18n.t('虚拟商品'),
    GIFT: i18n.t('赠品'),
  },
  saleTypeMapping: {
    FRESHLY_MADE_AND_SOLD: i18n.t('现制现售'),
    STOCK_UP_FOR_SALE: i18n.t('备货销售'),
    PURCHASE_SALE: i18n.t('采购销售'),
  },
  rate:
    localStorage.getItem('zoomRate') ||
    (window.devicePixelRatio >= 2 ? 3.1 / window.devicePixelRatio : 1),
}

function getData(name, defaultData) {
  let data = localStorage.getItem(name)
  if (data === undefined || data === 'undefined' || data === null || data === 'null') {
    return defaultData
  }
  try {
    return JSON.parse(data)
  } catch (err) {
    console.log(err)
  }
  return data
}
