const baseURL = "https://intl.oms.geezdata.com/";
const aftersaleURL = "https://aftersale.shopspade.com/api/";
const regionURL = "https://region.shopspade.com/api/";
const inventoryURL = "https://inventory.shopspade.com/api/";
const reportURL = "https://task.shopspade.com/api/";
const orderURL = "https://order.shopspade.com/api/";
const plymouthURL = "https://plymouth.shopspade.com/api/";
const idaasApi = "https://idaas.shopspade.com/api/";
const goodsApi = "https://product.shopspade.com/api/";
const shopApi = "https://shop.shopspade.com/api/";
const workorderApi = "https://workorder.shopspade.com/api/";
const myjApi = "https://myj.shopspade.com/api/";


const fileHost = "https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER";
const frontURL = "https://intl.oms.geezdata.com";
const idaasURL = "https://idaas-cdn.shopspade.com";
const redirectUri = "https://intl.oms.geezdata.com/oms_api/oauth/lazada";
const inventoryWebEntry = "https://inventory.shopspade.com";
const reportWebEntry = "https://task.shopspade.com";
const biErpWebEntry = "https://bi.shopspade.com";
const scmURL = "https://scm.shopspade.com";
const aftersaleWebEntry = "https://aftersale.shopspade.com";
const workorderWebEntry = "https://workorder.shopspade.com";
const scmWebEntry = "https://scm.shopspade.com";
const wmshubWebEntry = "https://wmshub.shopspade.com";
const campaignWebEntry = "https://campaign.shopspade.com";
const prepaymentEntry = "https://prepayment.shopspade.com";
const bestsellerEntry = "https://bestsellers.shopspade.com";
const shopWebEntry = "https://shop.shopspade.com";
const strategyWebEntry = "https://strategy.shopspade.com"; //策略中心
const goodsWebEntry = "https://product.shopspade.com"; //商品
const regionWebEntry = "https://region.shopspade.com"; //地址库
const biEntry = "https://bi.shopspade.com/qbiDashboard"
const orderWebEntry = "https://order.shopspade.com"; //订单
const financeWebEntry = "https://fms.geezdata.com"; //财务


const wsURL = 'wss://plymouth.shopspade.com/wsapi/'
const singleSignOnURL =
  idaasURL + '/api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=OMS&terminal=PC&redirect_uri=' +
  frontURL + '&idaas_uri=' + idaasURL
const singleSignOutURL = idaasURL + '/logout'


export {
  aftersaleURL,
  regionURL,
  inventoryURL,
  reportURL,
  orderURL,
  plymouthURL,
  idaasApi,
  goodsApi,
  shopApi,
  workorderApi,
  fileHost,
  frontURL,
  baseURL,
  idaasURL,
  redirectUri,
  inventoryWebEntry,
  reportWebEntry,
  biErpWebEntry,
  scmURL,
  aftersaleWebEntry,
  workorderWebEntry,
  scmWebEntry,
  wmshubWebEntry,
  campaignWebEntry,
  prepaymentEntry,
  bestsellerEntry,
  shopWebEntry,
  strategyWebEntry,
  goodsWebEntry,
  regionWebEntry,
  biEntry,
  wsURL,
  myjApi,
  orderWebEntry,
  financeWebEntry,
  singleSignOnURL,
  singleSignOutURL
};
