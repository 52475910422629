import {
  aftersaleWebEntry,
  workorderWebEntry,
  scmWebEntry,
  // bestsellerEntry,
  inventoryWebEntry,
  reportWebEntry,
  biErpWebEntry,
  // campaignWebEntry,
  wmshubWebEntry,
  shopWebEntry,
  strategyWebEntry,
  goodsWebEntry,
  regionWebEntry,
  orderWebEntry,
  // prepaymentEntry,
  financeWebEntry,
} from '../config/config'
import actions from './actions'
import router from '@/router.js'
import store from '@/store'
import { priv } from '@/common/privilege.js'

const microApps = [
  /**
   * name: 微应用名称 - 具有唯一性
   * entry: 微应用入口 - 通过该地址加载微应用
   * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
   * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
   */
  //报表系统
  //订单
  {
    name: 'orderManage',
    entry: orderWebEntry,
    container: '#subapp-viewport',
    activeRule: '/orderManage',
  },
  //售后系统
  {
    name: 'aftersale_web',
    entry: aftersaleWebEntry,
    container: '#subapp-viewport',
    activeRule: '/aftersale_web',
    props: { parentRouter: router },
  },
  //采购系统
  {
    name: 'purchase',
    entry: scmWebEntry,
    container: '#subapp-viewport',
    activeRule: '/purchase',
  },
  // //分销管理
  // {
  //   name: 'distributionManagement',
  //   entry: bestsellerEntry,
  //   container: '#subapp-viewport',
  //   activeRule: '/distributionManagement',
  // },
  // //分销平台
  // {
  //   name: 'distributionPlatform',
  //   entry: bestsellerEntry,
  //   container: '#subapp-viewport',
  //   activeRule: '/distributionPlatform',
  // },
  //工单系统
  {
    name: 'workorder_web',
    entry: workorderWebEntry,
    container: '#subapp-viewport',
    activeRule: '/workorder_web',
    props: { parentRouter: router },
  },
  //库存系统
  {
    name: 'inventory_web',
    entry: inventoryWebEntry,
    container: '#subapp-viewport',
    activeRule: '/inventory_web',
  },
  //task
  {
    name: 'report_web',
    entry: reportWebEntry,
    container: '#subapp-viewport',
    activeRule: '/report_web',
  },
  //dashboard
  {
    name: 'dashboard_web',
    entry: biErpWebEntry,
    container: '#subapp-viewport',
    activeRule: '/dashboard_web',
    props: { parentRouter: router },
  },
  {
    name: 'order',
    entry: wmshubWebEntry,
    container: '#subapp-viewport',
    activeRule: '/order',
  },
  //活动管理
  // {
  //   name: 'campaign_web',
  //   entry: campaignWebEntry,
  //   container: '#subapp-viewport',
  //   activeRule: '/campaign_web',
  //   props: { parentRouter: router },
  // },
  //店铺设置
  {
    name: 'shop_web',
    entry: shopWebEntry,
    container: '#subapp-viewport',
    activeRule: '/shop_web',
  },
  //策略中心
  {
    name: 'strategy_web',
    entry: strategyWebEntry,
    container: '#subapp-viewport',
    activeRule: '/strategy_web',
  },
  //商品
  {
    name: 'goodsManage',
    entry: goodsWebEntry,
    container: '#subapp-viewport',
    activeRule: '/goodsManage',
  },
  //地址库
  {
    name: 'region_web',
    entry: regionWebEntry,
    container: '#subapp-viewport',
    activeRule: '/region_web',
  },
  //财务
  {
    name: 'fms_web',
    entry: financeWebEntry,
    container: '#subapp-viewport',
    activeRule: '/fms_web',
  },
  // prepayment
  // {
  //   name: 'prepayment_web',
  //   entry: prepaymentEntry,
  //   container: '#subapp-viewport',
  //   activeRule: '/prepayment_web',
  // },
]

const apps = microApps.map((item) => {
  return {
    ...item,
    activeRule: window.__POWERED_BY_QIANKUN_PARENT__ ? `OMS${item.activeRule}` : item.activeRule,
    props: {
      parentRouter: router,
      routerBase: item.activeRule,
      getGlobalState: actions.getGlobalState,
      addPane: (data) => store.commit('addPane', data),
      store,
      priv,
      ...item.props,
    },
    loader: (loading) => {
      if (loading) {
        store.commit('setState', { dashboard_loading: true, loading_type: item.name })
      } else {
        store.commit('setState', { dashboard_loading: false })
      }
    },
  }
})

export default apps
