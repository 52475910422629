import i18n from '@/utils/lang'
var constants = {
  //作废涉及状态
  CLOSE_ORDER_STATUSES: ['CANCELED', 'CLOSE_PLATFORM', 'CLOSED_MANUAL', 'SUSPEND'],
  //审核设计装填
  WAIT_CHECK_STATUS: ['WAIT_CHECK', 'TO_REVIEW'],
  //币种列表
  CURRENCY_LIST: ['CNY', 'JPY', 'GBP', 'THB', 'USD', 'TWD'],
  //批量任务类型
  TASK_TYPE_ARRAY: [
    'BATCH_CHECK', //'批量审核',
    'BATCH_CANCEL', //'批量作废',
    'BATCH_REVERT_WAIT_CHECK', //'批量恢复待审核',
    'BATCH_ADD_GIFT', //'批量增加赠品',
    'BATCH_DELETE_GIFT', //'批量删除赠品',
    'BATCH_CHANGE_WAREHOUSE', //'批量转仓',
    'BATCH_CHANGE_SHIPPING', //'批量转快递',
    'BATCH_REJECT', //'批量驳回',
    // 'BATCH_CHANGE_BOOK', //'正常单转异常单',
    // 'BATCH_CHANGE_NORMAL', //'异常单转正常单',
    'BATCH_SUSPEND', //'批量挂起',
    'BATCH_CHANGE_GOODS', //'批量替换商品',
    'BATCH_CLOSE', //批量关闭,
    'BATCH_SPLIT_BY_BARCODE', //批量拆单
  ],
  //订单状态类型
  order_status_list: [
    'WAIT_CHECK', //待预定
    'TO_REVIEW', //待审核
    'CHECK_PASS', //待推送
    'PUSHED', //已推送
    'REJECT', //已拒收
    'CANCELED', //已作废
    'CLOSE_PLATFORM', //已作废
    'CLOSED_MANUAL', //已作废
    'INVALID', //无效
    'CLOSE_PLATFORM_PAID', //已付款，但平台关闭
    'CANCELING', //取消中
  ],
  //发货状态类型
  SHIPPING_STATUS_LIST: [
    'PENDING_WAYBILL', //待处理
    'WAYBILL_SUCCESS', //获取面单成功
    'PUSH', //已推送
    'OPERATED', //已发货
    'PICKED', //已发货
    'PLATFORM_OPERATED', //平台已发货
    'RECEIVED', //用户签收
    'REJECTED', //已驳回
  ],
  ORDER_TYPE_MAPPING: {
    B2B: i18n.t('B2B'),
    SALE: i18n.t('销售'),
    PRESALE: i18n.t('预售'),
    EXCHANGE: i18n.t(`换货`),
    SAMPLES_SENDING: i18n.t(`寄样`),
    SHIP_ONLY: i18n.t(`补寄`),
    DISTRIBUTOR: i18n.t(`分销`),
    INTERNAL_USE: i18n.t(`内领`),
    SCRAP: i18n.t(`报废`),
  },
  //订单id输入类型
  ORDER_ID_TYPE: ['PLATFORM_ORDER_SN', 'ORDER_SN', 'WAYBILL_NO'], //原始订单号 OMS订单号 快递面单号——原始订单页用
  ORDER_SN_TYPE: ['platformOrderSn', 'orderSn', 'waybillNo'], //原始订单号 OMS订单号 快递面单号——oms订单页用
  COLLECT_METHOD_LIST: ['DROP_OFF', 'PICK_UP'], //DROP_OFF(送货上门) PICK_UP(上门揽件)
  TIMEOUT_SITUATION_LIST: ['ATBSLA', 'SLAB'], //履约SLA
}

global.constants = constants
export default constants
