<template>
  <div id="app" class="oms-app">
    <a-locale-provider :locale="app_locale">
      <a-spin class="global-spin" :spinning="$store.state.loading">
        <router-view />
      </a-spin>
    </a-locale-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import { ConfigProvider } from 'ant-design-vue'
import 'moment/locale/zh-cn'
import { mapState, mapMutations } from 'vuex'
import { setCookie } from '@common/cookie'
export default {
  components: {
    'a-locale-provider': ConfigProvider,
  },
  computed: {
    ...mapState(['lang', 'locale']),
    app_locale() {
      return this.locale || zh_CN
    },
  },
  mounted() {
    // let t = window.devicePixelRatio
    let rate = 1
    // if (t >= 2) {
    //   document.body.style.zoom = t / 2.5
    //   rate = 3.1 / t
    // }
    localStorage.setItem('zoomRate', rate)
    this.changeLang(this.lang)
    this.changeLanguage(this.lang)
  },
  methods: {
    ...mapMutations(['setState', 'changeLang']),
    changeLanguage(lang) {
      this.$i18n.locale = lang
      let language_mapping = {
        zh: 'zh_CN',
        en: 'en_US',
        th: 'th_TH',
      }
      moment.locale(lang)
      setCookie({
        language: language_mapping[lang],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
