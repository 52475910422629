import api from '../service/api'

async function priv() {
  return true
  // await Common.priv.init(api.resourceCodes)
  // return Common.priv.hasUp(val)
}

function privHas() {
  Common.priv.init(api.listResourceCodes)
  return Common.priv.hasUp.apply(Common.priv, arguments)
}

export { priv, privHas }
